<template>
    <nav>
        <div class="container">
            <div class="nav">
                <a class="logo">
                    <div class="logo-img">
                        <router-link :to="logoLink">
                            <img src="@/assets/img/apeiron_logo.png" alt="Apeiron Education" />
                        </router-link>
                    </div>
                    <div class="logo-text">
                        <router-link :to="logoLink">
                            <p>Apeiron Education</p>
                        </router-link>
                    </div>
                </a>
                <div class="top-nav align-center" :class="{ open: isMenuOpen }">
                    <div class="dropdown" @click="toggleDropdown">
                        <a class="dropdown-menu">
                            <i class="fa-solid fa-user"></i>
                            {{ this.$store.state.user.name | adminDisplayName }}
                            <span><i class="fa-solid fa-caret-down"></i></span>
                        </a>
                        <ul class="dropdown-item dropdown_menu--animated dropdown_animation" :class="{ show: isDropdownOpen }">
                            <li v-if="id != '0001'">
                                <router-link :to="{
                                    name: 'school',
                                    params: { id: this.$store.state.user.campus_id },
                                }">
                                    <a>Campus Info</a>
                                </router-link>
                            </li>
                            <li v-if="id == '0001'">
                                <router-link :to="{ name: 'campusList' }">
                                    <a>Campus</a>
                                </router-link>
                            </li>
                            <li v-if="id == '0001'">
                                <router-link :to="{ name: 'adminList' }">
                                    <a>Admin</a>
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'updatePassword' }">
                                    <a>Update Password</a>
                                </router-link>
                            </li>
                            <li v-if="id != '0001' && role == 1">
                                <router-link :to="{ name: 'settingPage' }">
                                    <a>Setting</a>
                                </router-link>
                            </li>
                            <li class="border">
                                <a @click="logoutUser" class="btn-cursor"><i class="fa-solid fa-right-from-bracket"></i>
                                    Logout</a>
                            </li>
                        </ul>
                    </div>
                    <div class="hamburger" @click="toggleMenu" :class="{ open: isMenuOpen }" v-if="id != '0001'">
                        <div class="hamburger-line" v-for="index in 3" :key="index"></div>
                    </div>
                </div>
            </div>
            <div class="nav-menu" :class="{ open: isMenuOpen }" v-if="id != '0001'">
                <ul class="nav-menu-list">
                    <li class="dropdown" :class="{ active: $route.name == 'dashboard' }">
                        <router-link :to="{ name: 'dashboard' }"><a class="dd-hover"><i
                                    class="fa-solid fa-house"></i>Dashboard</a></router-link>
                    </li>
                    <li class="dropdown" :class="{ active: $route.name == 'adminList' }" v-if="role == 1">
                        <router-link :to="{ name: 'adminList' }"><a class="dd-hover"><i
                                    class="fa-solid fa-users-gear"></i>Admins</a></router-link>
                    </li>

                    <!-- Master CRUD -->
                    <li class="dropdown" :class="{
                        active:
                            $route.name == 'periodList' ||
                            $route.name == 'gradeList' ||
                            $route.name == 'holidayList' ||
                            $route.name == 'paymentMethodList' ||
                            $route.name == 'teacherTypeList',
                    }">
                        <a class="dropdown-menu" @click="toggleDropdown"><i class="fa-solid fa-gears"></i>Master</a>
                        <ul class="dropdown-item dropdown_menu--animated dropdown_animation"
                            :class="{ show: isDropdownOpen }">
                            <li>
                                <router-link :to="{ name: 'periodList' }"><a>Academic Period List</a></router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'gradeList' }"><a>Grade List</a></router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'holidayList' }"><a>Holiday List</a></router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'paymentMethodList' }"><a>Payment Method List</a></router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'teacherTypeList' }"><a>Teacher Type List</a></router-link>
                            </li>
                        </ul>
                    </li>

                    <li class="dropdown" :class="{
                        active:
                            $route.name == 'classList' ||
                            $route.name == 'gradeFeeList' ||
                            $route.name == 'timetableList' ||
                            $route.name == 'timetableMetaList' ||
                            $route.name == 'subjectList' ||
                            $route.name == 'campusGradeList',
                    }">
                        <a class="dropdown-menu" @click="toggleDropdown"><i class="fa-solid fa-chalkboard-user"></i>Grade</a>
                        <ul class="dropdown-item dropdown_menu--animated dropdown_animation"
                            :class="{ show: isDropdownOpen }">
                            <li>
                                <router-link :to="{ name: 'campusGradeList' }"><a>Campus Grade List</a></router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'gradeFeeList' }"><a>Grade Fee List</a></router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'classList' }"><a>Class List</a></router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'subjectList' }"><a> Subject List</a></router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'timetableMetaList' }"><a>Timetable Meta List</a></router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'timetableList' }"><a>TimeTable List</a></router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="dropdown" :class="{
                        active:
                            $route.name == 'teacherList' ||
                            $route.name == 'attendanceTeacherList' ||
                            $route.name == 'subjectTeacher' ||
                            $route.name == 'salaryList' ||
                            $route.name == 'courseList',
                    }">
                        <a class="dropdown-menu" @click="toggleDropdown"><i class="fa-solid fa-people-roof"></i>Teachers</a>
                        <ul class="dropdown-item dropdown_menu--animated dropdown_animation"
                            :class="{ show: isDropdownOpen }">
                            <li>
                                <router-link :to="{ name: 'teacherList' }"><a>Teacher List</a></router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'subjectTeacherList' }"><a>Subject Assign List</a></router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'courseList' }"><a>Course List</a></router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'attendanceTeacherList' }"><a>Attendance List</a></router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'salaryList' }"><a>Salary List</a></router-link>
                            </li>
                            <li v-if="$store.state.setting.teacherChatApproval">
                                <router-link :to="{ name: 'chatRequestList' }"><a>Chat Request List</a></router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="dropdown" :class="{
                        active:
                            $route.name == 'parentList' ||
                            $route.name == 'studentList' ||
                            $route.name == 'attendanceStudentList' ||
                            $route.name == 'gradingList',
                    }">
                        <a class="dropdown-menu" @click="toggleDropdown"><i class="fa-solid fa-people-line"></i>Students</a>
                        <ul class="dropdown-item dropdown_menu--animated dropdown_animation"
                            :class="{ show: isDropdownOpen }">
                            <li>
                                <router-link :to="{ name: 'parentList' }">
                                    <a> Parent List</a>
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'studentList' }">
                                    <a> Student List</a>
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'gradingList' }">
                                    <a> Grading List</a>
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'attendanceStudentList' }">
                                    <a>Attendance List</a>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="dropdown" :class="{
                        active:
                            $route.name == 'paymentList' ||
                            $route.name == 'inOutcomeList' ||
                            $route.name == 'itemList',
                    }" v-if="role == 1">
                        <a class="dropdown-menu" @click="toggleDropdown"><i class="fa-solid fa-coins"></i>Finance</a>
                        <ul class="dropdown-item dropdown_menu--animated dropdown_animation"
                            :class="{ show: isDropdownOpen }">
                            <li>
                                <router-link :to="{ name: 'itemList' }"><a>Item List</a></router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'paymentList' }"><a>Payment List</a></router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'transactionList' }"><a>Transaction List</a></router-link>
                            </li>
                        </ul>
                    </li>
                    <!-- <li class="dropdown" :class="{
                        active:
                            $route.name == 'driverList' ||
                            $route.name == 'ferryList' ||
                            $route.name == 'routeList' ||
                            $route.name == 'dailyFerryList' ||
                            $route.name == 'ferryPickupList',
                    }">
                        <a class="dropdown-menu" @click="toggleDropdown"><i class="fas fa-bus-alt"></i>Ferry</a>
                        <ul class="dropdown-item dropdown_menu--animated dropdown_animation"
                            :class="{ show: isDropdownOpen }">
                            <li>
                                <router-link :to="{ name: 'driverList' }"><a>Driver List</a></router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'ferryList' }"><a>Ferry List</a></router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'routeList' }"><a>Route List</a></router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'ferryPickupList' }"><a>Ferry Pickup List</a></router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'dailyFerryList' }"><a>Daily Ferry List</a></router-link>
                            </li>
                        </ul>
                    </li> -->
                    <li class="dropdown" :class="{active:$route.name == 'noticeList' || $route.name == 'leaveList'}">
                        <a class="dropdown-menu" @click="toggleDropdown"><i class="fa-solid fa-bullhorn"></i>Notices</a>
                        <ul class="dropdown-item dropdown_menu--animated dropdown_animation"
                            :class="{ show: isDropdownOpen }">
                          <li>
                            <router-link :to="{ name: 'noticeList' }">Notice List</router-link>
                          </li>
                          <li>
                            <router-link :to="{ name: 'leaveList' }">Leave List</router-link>
                          </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import store from "@/store";
import axios from "axios";
export default {
    name: "HeaderPage",
    data() {
        return {
            role: store.state.user.role,
            id: store.state.user.admin_no,
            campusCode: store.state.user.campus_master.campus_code,
            isMenuOpen: false,
            isDropdownOpen: false,
        };
    },

    computed: {
        logoLink() {
        return this.id === '0001' ? { name: 'adminList' } : { name: 'dashboard' };
        }
    },

    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        logoutUser() {
            axios
                .delete("/logout")
                .then((res) => {
                    if (res.data.success == true) {
                        this.$store.commit("logout");
                        this.$router.push({ name: "login" });
                    }
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit("logout");
                        window.location.replace("login");
                    }
                });
        },
        getDetail() {
            var request = {
                params: {
                    campus_id: this.$store.state.user.campus_id
                }
            }
            axios.get(`admin/detail/${this.$store.state.user.id}`, request)
                .then(res => {
                    if (res.data) {
                        this.$store.state.user.name = res.data.name;
                        this.$store.state.user.email = res.data.email;
                    }
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Admin Detail API Error", error);
                })
        }
    },

    mounted() {
        this.isLoading = true;
        this.getDetail();
    },
};
</script>
