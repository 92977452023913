import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { required } from 'vuelidate/lib/validators'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Vue from 'vue'
import CONFIG from "@/assets/js/config.js";
import draggable from 'vuedraggable';

export default {
  name: "timetableMetaEdit",
  components: {
    MainLayout,
    Loading,
    flatPickr,
    draggable,
  },

  data() {
    return {
      noDataMsg: CONFIG.NO_DATA_EXIST,
      submitted: false,
      isLoading: false,
      metaData: {},
      period_name: null,
      class_name: null,
      selectBoxes: [],
      timeArr: [],
      time_ErrMsg: [],
      duplicateErr: [],

      configDateTime: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: false,
        minuteIncrement: 1,
      },
    };
  },

  validations: {
    selectBoxes: {
      required,
      $each: {
        value: {
          required
        }
      }
    },
    timeArr: {
      $each: {
        start_time: {
          required
        },
        end_time: {
          required
        }
      }
    }
  },

  methods: {
    getDetail() {
      axios.get(`timetableMeta/detail/${this.$route.params.id}`)
        .then(res => {
          this.metaData = res.data;
          if (Object.keys(this.metaData).length != 0) {
            this.period_name = this.metaData[0].period_name;
            this.class_name = this.metaData[0].class_name
            this.metaData.forEach(item => {
              if (item.type === 1) {
                this.selectBoxes.push({ value: item.value, id: item.id, type: item.type, old_value: item.value });
              } else if (item.type === 2) {
                const [start, end] = item.value.split("~");
                this.timeArr.push({ start_time: start, end_time: end, id: item.id, type: item.type, old_value: item.value });
              }
            });
          }

          this.isLoading = false;
        })
      .catch(error => {
        if (error.res.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },

    cloneData(index, fieldName) {
      if (fieldName == 'selectBox') {
        if (this.selectBoxes.length < 7) {
          const clonedSelectBox = { value: this.selectBoxes[index].value, id: 0 };
          this.selectBoxes.splice(index + 1, 0, clonedSelectBox);
        }
      }
      if (fieldName == 'timePickr') {
        if (this.timeArr.length < 13) {
          const clonedTimeData = {
            start_time: this.timeArr.start_time,
            end_time: this.timeArr.end_time,
            id: 0,
          };
          this.timeArr.splice(index + 1, 0, clonedTimeData);
          this.time_ErrMsg.splice(index + 1, 0, '');
        }
      }

    },

    removeCloneData(index, fieldName) {
      if (index != 0) {
        if (fieldName == 'selectBox') {
          Vue.delete(this.selectBoxes, index);
        }
        if (fieldName == 'timePickr') {
          Vue.delete(this.timeArr, index);
        }
      }
    },

    updateValue(index, fieldName) {
      if (fieldName == 'selectBox') {
        this.duplicateErr[index] = '';
      }
      if (fieldName == 'timePickr') {
        this.time_ErrMsg[index] = '';
      }
    },

    hasDuplicate(index) {
      const selectedValue = this.selectBoxes[index].value;
      return this.selectBoxes
        .filter((_, i) => i !== index)
        .some((box) => box.value === selectedValue);
    },

    timeTableUpdate() {
      this.duplicateErr = [];
      const duplicateIndex = this.selectBoxes.findIndex((box, index) =>
        index !== this.selectBoxes.length - 1 && this.hasDuplicate(index)
      );
      if (duplicateIndex !== -1) {
        this.duplicateErr[duplicateIndex] = "Duplicate days are not allowed.";
      }

      this.time_ErrMsg = [];
      for (let index = 0; index < this.timeArr.length; index++) {
        const start_element = this.timeArr[index].start_time;
        const end_element = this.timeArr[index].end_time;
        if (start_element != null && end_element != null && start_element >= end_element) {
          this.time_ErrMsg[index] = "Start time is greater than or equal to end time.";
        }
      }
      if (this.$v.$invalid || this.time_ErrMsg.length != 0 || this.duplicateErr.length != 0) {
        this.submitted = true;
        return;
      }
      this.update();
    },

    update() {
      this.isLoading = true;
      axios.post("timetableMeta/update", {
        class_id: this.metaData[0].class_id,
        login_id: this.$store.state.user.id,
        selectBoxes: this.selectBoxes,
        timeArr: this.timeArr,
        oldData: this.metaData,
        campus_id: this.$store.state.user.campus_id
      })
        .then(response => {
          if (response.data.status == 200) {
            this.$notification.success("Timetable Meta Update Successfully", {
              timer: 3,
              position: "bottomCenter",
            });
            this.$router.push({
              name: "timetableMetaList"
            });
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
  },

  mounted() {
    this.isLoading = true;
    this.getDetail();
  },
};
