import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { required } from 'vuelidate/lib/validators'
import Vue from 'vue'
import store from "@/store";
import axios from "axios";

export default {
  name: "timetableMetaCreate",
  components: {
    MainLayout,
    Select2Multiple,
    Loading,
    flatPickr,
  },

  data() {
    return {
      isLoading: false,
      submitted: false,
      classArr: [],
      academicYearArr: null,
      selectedClass: null,
      class_id: null,
      acd_year_id: '',
      selectBoxes: [
        { value: 'Monday' },
        { value: '' }
      ],
      timeArr: [
        { 
          start_time : null,
          end_time : null 
        }
      ],
      configDateTime: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: false,
        minuteIncrement: 1,
      },
      duplicateErr: [],
      time_ErrMsg: [],
    };
    
  },

  validations: {
    acd_year_id: {
      required
    },
    selectedClass: {
      required
    },
    selectBoxes: {
      required,
      $each: {
        value: {
          required
          
        }
      }
    },
    timeArr: {
      $each: {
        start_time: {
          required
        },
        end_time: {
          required
        }
      }
    }
  },

  methods: {
    getCurrentPeriodList() {
      axios.get('/getAcademicYear', {
        params: {
          campus_id: store.state.user.campus_id,
        }
      })
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Academic Year'
          }];
          this.academicYearArr = $defaultSelectOption.concat(res.data.academicYearArr);
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getClass($yearId) {
      axios.get("class/classList/" + $yearId, {
        params: {
          campus_id: store.state.user.campus_id,
        }
      })
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Class'
          }];
          this.classArr = $defaultSelectOption.concat(res.data);
          if (this.classArr.length > 1) {
            this.noClassMsg = '';
          } else {
            this.noClassMsg = 'There is no class to show.';
          }
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    changeAcademicYearEvent($acd_year_id) {
      this.class_id = null;
      this.noClassMsg = '';
      this.getClass($acd_year_id);
    },

    cloneData(index, fieldName) {
      if (fieldName == 'selectBox') {
        if (this.selectBoxes.length < 7) {
          const clonedSelectBox = { value: this.selectBoxes[index].value };
          this.selectBoxes.splice(index + 1, 0, clonedSelectBox);
        }
      }
      if (fieldName == 'timePickr') {
        if (this.timeArr.length < 13) {
          const clonedTimeData = {
            start_time: this.timeArr[index].start_time,
            end_time: this.timeArr[index].end_time,
          };
          this.timeArr.splice(index + 1, 0, clonedTimeData);
        }
      }
    },

    removeCloneData(index, fieldName) {
      if (index != 0) {
        if (fieldName == 'selectBox') {
          Vue.delete(this.selectBoxes, index);
        }
        if (fieldName == 'timePickr') {
          Vue.delete(this.timeArr, index);
        }
      }
    },

    updateValue(index, fieldName) {
      if (fieldName == 'selectBox') {
        this.duplicateErr[index] = '';
      }
      if (fieldName == 'time') {
        this.time_ErrMsg[index] = '';
      }
    },

    hasDuplicate(index) {
      const selectedValue = this.selectBoxes[index].value;
      return this.selectBoxes
        .filter((_, i) => i !== index)
        .some((box) => box.value === selectedValue);
    },

    confirmRegister() {
      this.duplicateErr = [];
      const duplicateIndex = this.selectBoxes.findIndex((box, index) => 
        index !== this.selectBoxes.length - 1 && this.hasDuplicate(index)
      );
      if (duplicateIndex !== -1) {
        this.duplicateErr[duplicateIndex] = "Duplicate days are not allowed.";
      }

      this.time_ErrMsg = [];
      for (let index = 0; index < this.timeArr.length; index++) {
        const start_element = this.timeArr[index].start_time;
        const end_element = this.timeArr[index].end_time;
        if (start_element != null && end_element != null && start_element >= end_element) {
          this.time_ErrMsg[index] = "Start time is greater than or equal to end time.";
        }
      }

      if (this.$v.$invalid || this.time_ErrMsg.length != 0 || this.duplicateErr.length != 0) {
        this.submitted = true;
        return;
      }
      this.create();
    },

    create() {
      axios.post("timetableMeta/create", {
        class_id: this.selectedClass,
        selectBoxes: this.selectBoxes,
        timeArr: this.timeArr,
        login_id: this.$store.state.user.id
      })
        .then(res => {
          if (res.data.success == true) {
            this.$notification.success("Timetable Meta Added Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: "timetableMetaList"
            });
          } else {
            this.disabled = false;
            this.$notification.error(res.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
        }) .catch(error => {
          this.disabled = false;
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
        });
    }
  },

  mounted() {
    this.getCurrentPeriodList();
  },
};
