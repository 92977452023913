import Vue from 'vue';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import store from '../../store';
import axios from "axios";
import {
    required,
    maxLength
} from "vuelidate/lib/validators";

export default {
    name: "SubjectCreate",
    components: {
        MainLayout,
        Select2Multiple,
        Loading
    },

    data() {
        return {
            noDataMsg: CONFIG.NO_DATA_FOUND,
            isLoading: false,
            tmpResult: false,
            campusGradeArr: null,
            subject_name: '',
            description: '',
            old_grade_id: 0,
            inputData: {
                campus_grade_id: '',
                subject_name: '',
                description: '',
            },
            subjectInfo: [],
            hidden: false,
            firstname_ErrMsg: '',
            message: false,
            tmp_input: [],
        };
    },

    validations: {
        inputData: {
            campus_grade_id: {
                required
            },
            subject_name: {
                required,
                maxLength: maxLength(100)
            },
            description: {
                maxLength: maxLength(1000)
            },
        }
    },

    methods: {
        getCampusGradeName() {
            axios.get('getAcademicYearCampusGradeName', {
                params: {
                    campus_id: store.state.user.campus_id
                }
            }).then(res => {
                let $defaultSelectOption = [{
                    "id": 0,
                    "text": "Select"
                }];
                this.campusGradeArr = $defaultSelectOption.concat(res.data);
            }).catch((error) => {
                if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                this.isLoading = false;
            });
        },

        changeEvent(val, fieldName) {
            this.tmpResult = false;
            this.$set(this, fieldName, val);
            if (this.old_grade_id != val && this.old_grade_id != 0) {
                this.subjectInfo = [];
                this.inputData.subject_name = '';
                this.inputData.description = '';
            } else if (val == 0) {
                this.inputData.campus_grade_id = '';
            }
            this.$set(this, fieldName, val);
        },

        selectEvent({
            id
        }, fieldName) {
            id == 'Select' ? this.$set(this, fieldName, '') : this.$set(this, fieldName, id);
        },

        reset() {
            this.resetData();
        },

        showCreate() {
            this.$router.push({
                path: "/subject/create"
            });
        },

        resetEdit() {
            this.resetEditData();
            this.resetData();
            this.hidden = false;
        },

        resetEditData() {
            if (this.inputData.campus_grade_id && this.inputData.subject_name) {
                this.subjectInfo.push(Vue.util.extend({}, this.inputData));
            }
        },

        resetData() {
            this.tmp_input = [];
            this.inputData.subject_name = '';
            this.inputData.description = '';
        },

        editClone(index) {
            this.hidden = true;
            this.inputData = this.subjectInfo[index];
            this.tmp_input.push(Vue.util.extend({}, this.inputData));
            Vue.delete(this.subjectInfo, index);
        },

        removeClone(index) {
            Vue.delete(this.subjectInfo, index);
            if (this.subjectInfo.length == 0) {
                this.message = false;
            }
        },

        cloneForm() {
            this.tmp_input = [];
            this.firstname_ErrMsg = '';
            if (this.$v.$invalid) {
                this.tmpResult = true;
                return;
            }
            if (this.subjectInfo.length < 17) {
                this.old_grade_id = this.campus_grade_id;
                this.subjectInfo.push(Vue.util.extend({}, this.inputData));
                this.resetData();
                this.tmpResult = false;
                if (this.subjectInfo.length > 0) {
                    this.message = true;
                }
            } else {
                alert('Subject can be created at most 17.');
            }
        },

        subjectCreate() {
            this.isLoading = true;
            if (this.tmp_input.length > 0) {
                this.subjectInfo.push(Vue.util.extend({}, this.inputData));
            }
            this.firstname_ErrMsg = '';
            if (this.subjectInfo.length <= 0) {
                this.firstname_ErrMsg = 'Please Add Subject Data';
                this.isLoading = false;
                return;
            }
            this.tmpResult = false;
            axios.post('subject/create', {
                subjectInfo: this.subjectInfo,
                length: this.subjectInfo.length,
                login_id: store.state.user.id,
                campus_grade_id: this.subjectInfo[0]['campus_grade_id'],
            })
                .then(res => {
                    if (res.data.status == 200) {
                        this.$notification.success("Subject Added Successfully", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.$router.push({
                            name: 'subjectList'
                        })
                    }
                    if (res.data.status == 201) {
                        this.$notification.error("Subject Data Already Added according to Grade", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.isLoading = false;
                    } else {
                        this.errors = res.data.errors;
                        this.isLoading = false;
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    this.errors = error.response.data.errors;
                })
        }
    },
    mounted() {
        this.getCampusGradeName();
    },
};
