import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Select2Multiple from "v-select2-multiple-component";
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import { required, maxLength, minLength, email, numeric, sameAs } from "vuelidate/lib/validators";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
import { ConvertNumService } from '@/assets/js/convertNumberService.js';
import CONFIG from "@/assets/js/config.js";
import store from '@/store'

const convertNumberService = new ConvertNumService();

export default {
  name: "TeacherCreate",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      imageLargeMsg: CONFIG.IMAGE_TOO_LARGE,
      config: {
        maxDate: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + (new Date().getDate() - 1)
      },
      religionArr: null,
      teacherTypeArr: null,
      nrcNameJson: null,
      naingJson: null,
      districtList: [],
      naingList: [],
      nrcno: null,

      teacherImage: "",
      nrcFrontImage: "",
      nrcBackImage: "",
      signImage: "",

      teacherData: {
        teacher_type_id: '',
        name: '',
        password: '',
        dob: '',
        nationality: '',
        religion: '',
        marital_status: '',
        gender: '',
        father_name: '',
        email: '',
        phone1: '',
        phone2: '',
        contact_address: '',
        permanent_address: '',
        edu_background: '',
        other_certificate: '',
        work_experience: '',
        join_date: '',
        campus_id: store.state.user.campus_id,
        login_id: store.state.user.id,
        parent_chat_enable: CONFIG.TEACHER_PARENT_CHAT.DISABLE,
      },

      nrc_number: "",
      nrckey: "",
      selectedNrcName: "",
      selectedNrcNaing: "",
      nrcName: [],
      previewUrl: null,
      certificateFile: null,
      emailExists: null,

      submitted: false,
      disabled: false,
      isLoading: false,

      parent_chat_enable: CONFIG.TEACHER_PARENT_CHAT.ENABLE,
      parent_chat_disable: CONFIG.TEACHER_PARENT_CHAT.DISABLE,
    };
  },

  validations: {
    teacherData: {
      teacher_type_id: {
        required,
      },
      name: {
        required,
        maxLength: maxLength(100),
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(20),
      },
      confirm_password: {
        required,
        sameAsPassword: sameAs('password')
      },
      dob: {
        required,
        maxLength: maxLength(100),
      },
      gender: {
        required,
      },
      father_name: {
        required,
        maxLength: maxLength(50),
      },
      marital_status: {
        required,
      },
      nrc_number: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(6)
      },
      nationality: {
        required,
        maxLength: maxLength(100),
      },
      edu_background: {
        maxLength: maxLength(1000)
      },
      other_certificate: {
        maxLength: maxLength(1000)
      },
      work_experience: {
        maxLength: maxLength(1000)
      },
      join_date: {
        required,
      },
      email: {
        maxLength: maxLength(50),
        email
      },
      phone1: {
        required,
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(11)
      },
      phone2: {
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(11)
      },
      contact_address: {
        required,
        maxLength: maxLength(1000)
      },
      permanent_address: {
        maxLength: maxLength(1000)
      },
      parent_chat_enable: {
        required
      }
    },
  },

  methods: {
    async checkDuplicateEmail() {
      try {
          const response = await axios.get("checkDuplicateEmail", {
              params: {
                  model: 'Teacher',
                  email: this.teacherData.email,
              },
          });
          this.emailExists = response.data.exists;
          if(this.emailExists) {
            this.$notification.error("Email already existed.", {
              timer: 3,
              position: "bottomCenter",
            });
          }
          this.disabled = false;
      } catch (error) {
          console.error('An error occurred:', error);
      }
    },

    getReligion() {
      axios.get('getReligion')
        .then(res => {
          this.religionArr = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    async getNrcCity() {
      try {
        const response = await axios.get('getNrcCity')
        if (response) {
          this.nrcno = response.data;
        }
      } catch (error) {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      }
    },

    async getNrcNaing() {
      try {
        const response = await axios.get('getNrcNaing')
        if (response) {
          this.naingJson = response.data;
        }
      } catch (error) {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      }
    },

    async getNrcTownship() {
      try {
        const response = await axios.get('getNrcTownship')
        if (response) {
          this.nrcNameJson = response.data;
        }
      } catch (error) {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      }
    },

    getTeacherType() {
      axios.get('getTeacherType')
        .then(res => {
          this.teacherTypeArr = res.data.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    onChange(event) {
      var optionText = event.target.value;
      var convertNumberService = new ConvertNumService();
      var optionValue = convertNumberService.convertMm2Eng(optionText);
      if (optionValue) {
        this.nrckey = optionText;
        const placeholder = document.getElementById('nrc_placeholder');
        placeholder.style.display = 'none'
      } else {
        this.nrckey = optionValue;
      }
      this.selectedNrcName = "";
      this.districtList = this.changeNrcName(optionValue);
    },

    changeNrcName(value, nrcName) {
      nrcName = this.nrcNameJson[value];
      return nrcName;
    },

    onChangeNrcName(event) {
      var optionValue = event.target.value;
      if (!optionValue) {
        this.selectedNrcNaing = ""
        this.teacherData.nrc_number = ""
      }
      this.naingList = this.naingJson;
    },

    checkNRC() {
      if (
        this.nrckey !== undefined &&
        this.selectedNrcName !== undefined &&
        this.selectedNrcNaing !== undefined &&
        this.teacherData.nrc_number !== ""
      ) {
        return true;
      } else if (
        this.nrckey == undefined &&
        this.selectedNrcName == undefined &&
        this.selectedNrcNaing == undefined &&
        this.teacherData.nrc_number == ""
      ) {
        return true;
      } else return false;
    },

    mergeNRC() {
      return convertNumberService.convertEng2Mm(
        this.nrckey + "/" + this.selectedNrcName + this.selectedNrcNaing + this.teacherData.nrc_number
      );
    },

    chooseFiles(id) {
      document.getElementById(id).click();
    },

    uploadImage(id) {
      const inputfile = document.getElementById(id).files[0];
      if (inputfile) {
        if (inputfile.type.includes("image/")) {
          // validate for sign image
          if (id == 'signImage' && inputfile.type !== "image/png") {
            this.$notification.error("Only PNG file type allowed!", {
              timer: 3,
              position: "bottomCenter",
            });
            return null;
          }
          if (inputfile.type == "image/gif") {
            this.$notification.error("File not supported!", {
              timer: 3,
              position: "bottomCenter",
            });
            return null;
          }
          let reader = new FileReader();
          reader.readAsDataURL(inputfile);
          reader.onloadend = () => {
            if (reader.result.length > 5242880) {
              this.$notification.error(this.imageLargeMsg, {
                timer: 3,
                position: "bottomCenter",
              });
            } else {
              this.$set(this, id, reader.result);
            }
          };
        } else {
          this.$notification.error("File not supported!", {
            timer: 3,
            position: "bottomCenter",
          });
          return null;
        }
      }
    },

    removeImg(img) {
      this.$set(this, img, '');
      document.getElementById(img).value = '';
    },

    openFileInput() {
      this.$refs.fileInput.click();
    },

    handleFileChange(event) {
      const selectedFile = event.target.files[0];
      if (!selectedFile) return;

      const allowedTypes = ['.zip'];
      const fileType = selectedFile.name.substring(selectedFile.name.lastIndexOf('.')).toLowerCase();

      if (selectedFile.size > 10485760) {
        this.$notification.error("Zip File too large", {
          timer: 3,
          position: "bottomCenter",
        });
        this.$refs.fileInput.value = '';
      } else if (allowedTypes.includes(fileType)) {
        this.certificateFile = selectedFile;
        this.previewUrl = URL.createObjectURL(selectedFile);
      } else {
        this.$notification.error("File not supported!", {
          timer: 3,
          position: "bottomCenter",
        });
        this.$refs.fileInput.value = '';
      }
    },

    async create() {
      this.submitted = true;
      if (this.teacherData.mail) {
        await this.checkDuplicateEmail();
      }
      if (this.$v.$invalid || this.checkNRC() == false || this.emailExists) {
        return;
      }

      this.isLoading = true;
      this.disabled = true;

      const formData = new FormData();
      formData.append('teacher_type_id', this.teacherData.teacher_type_id);
      formData.append('name', this.teacherData.name);
      formData.append('password', this.teacherData.password);
      formData.append('dob', this.teacherData.dob);
      formData.append('nationality', this.teacherData.nationality);
      formData.append('religion', this.teacherData.religion);
      formData.append('marital_status', this.teacherData.marital_status);
      formData.append('gender', this.teacherData.gender);
      formData.append('father_name', this.teacherData.father_name);
      formData.append('email', this.teacherData.email);
      formData.append('phone1', this.teacherData.phone1);
      formData.append('phone2', this.teacherData.phone2);
      formData.append('contact_address', this.teacherData.contact_address);
      formData.append('permanent_address', this.teacherData.permanent_address);
      formData.append('edu_background', this.teacherData.edu_background);
      formData.append('other_certificate', this.teacherData.other_certificate);
      formData.append('work_experience', this.teacherData.work_experience);
      formData.append('join_date', this.teacherData.join_date);
      formData.append('campus_id', this.teacherData.campus_id);
      formData.append('login_id', this.teacherData.login_id);
      formData.append('nrc_number', this.mergeNRC());
      formData.append('profileImage', this.teacherImage);
      formData.append('nrcFrontImage', this.nrcFrontImage);
      formData.append('signImage', this.signImage);
      formData.append('nrcBackImage', this.nrcBackImage);
      formData.append('certificate_file', this.certificateFile);
      formData.append('parent_chat_enable', this.teacherData.parent_chat_enable);

      axios.post("/teacher/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          this.isLoading = false;
          if (response.data.success == true) {
            this.$notification.success("Teacher Added Successfully", {
              timer: 3,
              position: "bottomCenter",
            });
            this.$router.push({
              name: "teacherList",
            });
          } else {
            this.isLoading = false;
            this.disabled = false;
            this.$notification.error(response.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          if (error.response.status == 422) {
            this.isLoading = false;
            this.disabled = false;
            this.$notification.error(error.response.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
          this.disabled = false;
          this.isLoading = false;
          console.log("Teacher Create API Error", error.errors);
        });
    },
  },

  async mounted() {
    this.getReligion();
    await this.getNrcCity();
    await this.getNrcNaing();
    await this.getNrcTownship();
    this.getTeacherType();
  },
};
